.down-arrow {
  margin-top: 2em;
  width: 2.5em;
  height: 2.5em;
  border: 0.4em solid black;
  border-left: 0px;
  border-top: 0px;
  transform: rotate(45deg);
  cursor: pointer;
}
.center-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  background-image: url("../../Assets/matrix-intro-min.png");
  background-size: cover;
  height: 90vh;

  background-attachment: fixed;
}

.heading1 {
  padding-top: 1.5em;
  color: black;
  line-height: 1.2em;
  letter-spacing: 4px;
  font-size: 54px;
}

.center-inner-content {
  justify-content: center;
}
@media screen and (max-width: 767px) {
  .center-content {
    height: 70vh;
  }
  .mob-screen {
    margin-top: 20%;
  }
  .heading1 {
    font-size: 28px;
  }
}
