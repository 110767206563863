.headings {
  color: white;
  margin: 10px 60px;
}
.heading-1 {
  font-size: 3.8em;
}
.heading-2 {
  font-size: 1.35em;
}

.row-container {
  justify-content: center;
  text-align: center;
  margin: 30px 0;
}
.project-dash {
  width: 3px;
  height: 70px;
  background-color: white;
}
.project-image-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

.project-image {
  margin: 0 10px 20px 10px;
  width: 28em;
  position: relative;
  height: 28em;
  overflow: hidden;
  cursor: pointer;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.7s ease;
}

.project-image:hover img {
  transform: scale(1.1);
}

.project-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.project-overlay:hover {
  opacity: 1;
}

.overlay-text-proj {
  color: #3d5975;
  font-size: 24px;
  font-weight: bold;
}
.project-container {
  display: flex;
  justify-content: center;
}
.allprojects-container {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5em;
}
.allprojects-div {
  display: inline-flex;
  align-items: center;
  background-color: black;
  border-radius: 50%;
  padding: 0.6em;
}
.home-logo {
  width: 30px;
  height: 30px;
  object-fit: contain;
  filter: invert(1);
  border-radius: 50%;
  border: 2px solid white;
}
.project-title {
  margin-left: 0.8em;
  font-size: 1.4em;
  color: #3d5975;
  font-weight: bold;
  text-decoration: underline;
}
.project-con {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.proj-text {
  display: flex;
  justify-content: center;
  align-content: center;
}

@media screen and (max-width: 767px) {
  .project-image {
    height: 25em;
  }
}
@media screen and (max-width: 660px) {
  .project-image {
    height: 23em;
  }
}
@media screen and (max-width: 480px) {
  .project-image {
    height: 20em;
  }
}
