.dash-div {
  height: 10px;
  width: 120px;
  background-color: #edcd1f;
  margin: 10px 0 30px 0;
}
.partner-heading {
  letter-spacing: 0.05em;
  font-weight: 900;
  font-size: 3.5rem;
}
.wrapper {
  display: flex;
  width: 100%;
  padding-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.img-container {
  padding: 0 0 50px 0;
}
.img-wrapper {
  background-color: #f7f7f7;
  color: black;
}

.image-container {
  display: flex;
  flex-wrap: wrap;
}

.image {
  position: relative;
  margin: 10px;
}

.image img {
  max-width: 100%;
  height: auto;
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}

.overlay-text {
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.image:hover .overlay {
  opacity: 1;
}
