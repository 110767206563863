.about-section {
  background-image: url("../../Assets/matrix-2.png");
  background-size: cover; /* Cover the entire container */
  height: 950px;
  background-attachment: fixed;
  display: flex;
  align-items: flex-start; /* Align items to the start (top) */
  justify-content: center; /* Center horizontally */
}

.about-content {
  width: 100%;
  box-sizing: border-box;
}
.about-test {
  line-height: 1.4em;
  font-size: 1.1em;
}
.about-content-container {
  background-color: white;
  padding: 10px;
}

.about-text {
  padding-left: 30%;
}

@media screen and (max-width: 992px) {
  .about-text {
    padding-left: 20%;
  }
}
@media screen and (max-width: 767px) {
  .about-text {
    padding-left: 10%;
  }
}
@media screen and (max-width: 660px) {
  .about-text {
    padding-left: 0%;
  }
}
