.master-img {
  width: 45vw;
  height: 45vh;
  border: 5px solid white;
}
.carousel-img {
  width: 48vw;
  height: 40vh;
  border: 5px solid white;
  cursor: pointer;
}
.rec-proj-text {
  font-size: 1.2em;
  margin-bottom: 1.2em;
  margin-top: 1.2em;
}
.project-heading {
  justify-content: center;
}
.container-color {
  background-color: #f7f7f7;
}
.back-btn {
  border: 1px solid #3c3c3c;
  padding: 20px 50px;
  color: #edcd1f;
  font-size: medium;
  font-weight: bold;
  background-color: #3c3c3c;
  cursor: pointer;
}
.centering {
  justify-content: center;
}
