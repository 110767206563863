.service-mob1 {
  display: none;
}
.center-service-container {
  justify-content: center;
}
.service-main-container {
  margin-bottom: 150px;
}
.card-row-container {
  gap: 20px;
  align-items: flex-start;
}

.card-container {
  height: 35rem;
}

@media screen and (max-width: 900px) {
  .service-mob {
    display: none;
  }
  .service-mob1 {
    display: flex;
  }
  .service-main-container {
    margin-bottom: 80px;
  }
  .card-container {
    height: auto;
  }
}
