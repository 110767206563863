.map-Container {
  display: flex;
  justify-content: center;
}
.contact-info {
  justify-content: center;
}
.contact-info-2 {
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
}
.col-space {
  padding-top: 30px;
  font-size: large;
  line-height: 1.5;
}
.text-field {
  width: 100%;
  background-color: #f4f4f4;
  border: 1px solid;
}
.text-field:hover {
  background-color: black;
  background-color: #c4c4c4;
  border: 1px solid #747474;
}
.submit-btn {
  border: 1px solid #3c3c3c;
  padding: 7px 40px;
  color: #edcd1f;
  font-size: medium;
  font-weight: bold;
  background-color: #3c3c3c;
  cursor: pointer;
}
.submit-btn:hover {
  color: #3c3c3c;
  border: 1px solid #edcd1f;
  background-color: #edcd1f;
}

.btn-container {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: -10px;
}
.label-block {
  padding-bottom: 6px;
}
.text-area {
  width: 100%;
  resize: none;
}
.input-text {
  width: 100%;
  padding: 5px;
}
.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.contact-heading {
  letter-spacing: 0.05em;
  font-weight: 900;
  font-size: 3.5rem;
}
.contact-dash-div {
  height: 10px;
  width: 120px;
  background-color: #edcd1f;
  margin: 10px 0 55px 0;
}
.employement-text {
  overflow: hidden;
  font-size: medium;
  padding-top: 20px;
  line-height: 1.5;
}
.qoute-heading {
  font-size: x-large;
  text-align: center;
  padding: 30px 0 15px 0;
}
.qoute-dash-div {
  margin-top: 130px;
  height: 10px;
  width: 70px;
  background-color: #edcd1f;
}
.social-container {
  display: flex;
  justify-content: space-around;
}
.SM-heading {
  font-size: x-large;
  padding: 15px 0;
  text-align: center;
}
.employee-heading {
  font-size: x-large;
  padding-top: 150px;
}
.contact-us-heading {
  font-size: x-large;
  padding-bottom: 40px;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal Content */
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px; /* Increased border-radius for rounded corners */
  box-shadow: 0px 0px 20px #0000004d; /* Increased shadow */
  width: 90%; /* Adjust width as needed */
  max-width: 600px; /* Maximum width */
  max-height: 80vh; /* Maximum height to fit within viewport */
  overflow: auto; /* Enable scrolling if content exceeds max height */
  display: flex;
  flex-direction: column; /* Align content vertically */
  justify-content: center; /* Align content vertically */
  align-items: center; /* Align content horizontally */
  text-align: center; /* Align text horizontally */
}

/* Optionally, you can style the modal content further */
.modal-content h2 {
  color: #333;
  margin-bottom: 10px;
}

.modal-content p {
  color: #666;
}
.qoute-dash-container-mob {
  display: none;
}
@media screen and (max-width: 768px) {
  .map-Container {
    margin-top: -8%;
  }
  .qoute-dash-container {
    display: none;
  }
  .qoute-dash-container-mob {
    display: flex;
    justify-content: center;
  }
}
