* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Avenir", sans-serif;
  color: #222224;
}

.container {
  max-width: 1260px;
  padding: 0 15px;
  margin: auto;
}

.row {
  display: flex;
  margin: 0 -15px;
  align-items: center;
}

.col-12,
.col-9,
.col-6,
.col-8,
.col-5,
.col-4,
.col-3,
.col-2 {
  padding: 0 15px;
}

.col-12 {
  width: 100%;
}
.col-9 {
  width: 75%;
}

.col-8 {
  width: 66.67%;
}

.col-6 {
  width: 50%;
}
.col-5 {
  width: 41.66%;
}
.col-4 {
  width: 33.33%;
}

.col-3 {
  width: 25%;
}
.col-2 {
  width: 16.6666666667%;
}

ul {
  list-style: none;
  padding-left: 0 !important;
}

a {
  text-decoration: none;
}
