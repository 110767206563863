.component-heading-dash-div {
  height: 10px;
  width: 120px;
  background-color: #edcd1f;
  margin: 10px 0 55px 0;
}
.component-heading {
  letter-spacing: 0.05em;
  font-weight: 900;
  font-size: 3.5rem;
  color: #1e2d3b;
}
.aligning-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.component-header-container {
  padding: 60px 0 10px 0;
}
@media screen and (max-width: 600px) {
  .component-heading {
    font-size: 2.5rem;
    text-align: center;
  }
}
